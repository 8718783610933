import { isAfter } from "date-fns";
import { dateToYyyyMmDd } from "../utils/dates";
import { analyticsGroupingIntervalToDto, analyticssMetricToDto, dtoToAnalyticsMetrics } from "./Analytics.mutators";
import { AnalyticsGroupingInterval, AnalyticsMetric, AnalyticsResults } from "./Analytics.types";
import { TransformDomain } from "./types";

export type AnalyticsGetterOptions = { groupingInterval?: AnalyticsGroupingInterval; start: Date; end?: Date };

export class AnalyticsDomain extends TransformDomain<unknown, unknown> {
  resource = "Analytics";
  cacheKey = "analytics";

  getTeamAnalytics = this.typedManageErrors(
    async <METRICS extends AnalyticsMetric[]>(
      metrics: METRICS,
      options: AnalyticsGetterOptions
    ): Promise<AnalyticsResults<METRICS>> => {
      const { groupingInterval, start, end = new Date() } = options;
      if (isAfter(start, end)) throw new Error("End cannot come after start");

      return dtoToAnalyticsMetrics(
        await this.api.analytics.analyticsTeamAnalytics({
          start: dateToYyyyMmDd(start),
          end: dateToYyyyMmDd(end),
          metricName: metrics.map(analyticssMetricToDto),
          groupingInterval: analyticsGroupingIntervalToDto(groupingInterval || "DAILY"),
        })
      );
    }
  );

  getUserAnalytics = this.typedManageErrors(
    async <METRICS extends AnalyticsMetric[]>(
      metrics: METRICS,
      options: AnalyticsGetterOptions
    ): Promise<AnalyticsResults<METRICS>> => {
      const { groupingInterval, start, end = new Date() } = options;
      if (isAfter(start, end)) throw new Error("End cannot come after start");

      return dtoToAnalyticsMetrics(
        await this.api.analytics.analyticsUserAnalytics({
          start: dateToYyyyMmDd(start),
          end: dateToYyyyMmDd(end),
          metricName: metrics.map(analyticssMetricToDto),
          groupingInterval: analyticsGroupingIntervalToDto(groupingInterval || "DAILY"),
        })
      );
    }
  );
}
